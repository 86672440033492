<template>
  <div class="app-container">
    <el-dialog title="MY FILED FORMS APPROVERS" :visible.sync="dialogTableVisible" :fullscreen="isMobile">
      <el-table :data="approvers">
        <el-table-column prop="hierarchy_no" width="30"></el-table-column>
        <el-table-column prop="boss_em_id_name" label="NAME"></el-table-column>
        <el-table-column prop="a_id_name" label="STATUS">
          <template slot-scope="props">
           <el-tag :type="getStatusType(props.row.a_id_name)">{{ props.row.a_id_name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="a_id_remark" label="REMARKS"></el-table-column>
        <el-table-column prop="a_date_action" label="DATE ACTIONED" v-if="!isMobile">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px">{{ scope.row.a_date_action }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-card>
      <div slot="header" class="card-header">
        <h4 style="margin: 10;">MY FILED FORMS LIST</h4>
        <el-select v-model="status" placeholder="Select Application Status">
          <el-option v-for="(stat, index) in statuses" :key="index" :value="stat.value" :label="stat.name"></el-option>
        </el-select>
      </div>
      <div class="controls">
        <div class="control">
          <p>Items per page: </p>
          <el-select v-model="items">
            <el-option :label="5" :value="5"></el-option>
            <el-option :label="10" :value="10"></el-option>
            <el-option :label="25" :value="25"></el-option>
            <el-option :label="50" :value="50"></el-option>
            <el-option :label="100" :value="100"></el-option>
          </el-select>
        </div>
        <div class="control">
            <el-button-group>
              <el-button type="primary" icon="el-icon-arrow-left" @click="prevPage()" :disabled="!hasPreviousPage">Previous Page</el-button>
              <el-button type="primary" @click="nextPage()" :disabled="!hasNextPage">Next Page<i class="el-icon-arrow-right el-icon-right"></i></el-button>
            </el-button-group>
        </div>
      </div>
      <el-collapse accordion class="hidden-md-and-up">
        <el-collapse-item v-for="(file, index) in data" :key="index" :name="file.id">
          <template slot="title">
            <p style="width: 100%">{{ file.name }} [{{ formatDate(file.date_filed) }}]</p>
          </template>
          <p>Application Status: <el-tag :type="getStatusType(file.file_status)">{{ file.file_status }}</el-tag></p>
          <div v-if="file.multi_leave.length">
            <p>Date/s Covered:</p>
            <ul>
              <li v-for="(leave, index) in file.multi_leave" :key="index">
                {{ leave.bil_id_name }}, {{ formatDate(leave.leave_date) }} [{{ leave.duration_bg4_id_name }}]
              </li>
            </ul>
          </div>
          <div v-else>
            <p>Date/s Covered</p>
            <ul>
              <li v-for="(date, index) in file.date_dates.split(';')" :key="index">
                {{ formatDate(date) }}
              </li>
            </ul>
          </div>
          <div v-if="parseInt(file.bft_id) !== 1">
            <p>Time Covered (From-To): <b>{{ file.time_from }} - {{ file.time_to }}</b></p>
          </div>
          <p>Total Number of File: <b>{{ file.total_number_file }}</b></p>
          <p>Reason/s: <b>{{ file.reason }}</b></p>
          <div class="collapse-buttons">
            <el-tooltip class="item" effect="dark" content="Approval Status" placement="top-start">
              <el-button size="mini" type="primary" @click="showApprovalStatus(file.approvers)"><i class="el-icon-document-checked"></i></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Delete" placement="top-start">
              <el-button size="mini" type="danger" @click="confirmDelete(file)" v-if="!(file.a_id === '2' || file.is_lock === '1')"><i class="el-icon-delete"></i></el-button>
            </el-tooltip>
          </div>
        </el-collapse-item>
      </el-collapse>
      <el-table :data="data" border max-height="580" class="hidden-sm-and-down">
        <el-table-column type="expand">
          <template slot-scope="props">
          <div v-if="parseInt(props.row.bft_id) !== 1">
            <p class="expanded-p">Time Covered (From-To): <b>{{ props.row.time_from }} - {{ props.row.time_to }}</b></p>
          </div>
          <p class="expanded-p">Total Number of File: <b>{{props.row.total_number_file}}</b></p>
          <p class="expanded-p">Reason/s: <b>{{props.row.reason}}</b></p>
          </template>
        </el-table-column>
        <el-table-column label="FILE NO." prop="id" width="200">
        </el-table-column>
        <el-table-column label="APPLICATION TYPE" prop="name" width="200">
        </el-table-column>
        <el-table-column label="DATE FILED" prop="date_filed" width="200">
          <template slot-scope="props">
            <p>{{ formatDate(props.row.date_filed) }}</p>
          </template>
        </el-table-column>
        <el-table-column label="STATUS" prop="file_status" width="200">
          <template slot-scope="props">
           <el-tag :type="getStatusType(props.row.file_status)">{{ props.row.file_status }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="DATE/S COVERED" prop="date_dates" min-width="450">
          <template slot-scope="props">
            <div v-if="props.row.multi_leave.length">
              <p v-for="(leave, index) in props.row.multi_leave" :key="index" style="margin: 0;">
                {{ leave.bil_id_name }} - {{ leave.duration_bg4_id_name }} - {{ formatDate(leave.leave_date) }}
              </p>
            </div>
            <div v-else>
              <ul v-if="props.row.date_dates.includes(';')">
                <li v-for="(date, index) in props.row.date_dates.split(';')" :key="index">
                  {{ formatDate(date) }}
                </li>
              </ul>
              <p v-else>{{ formatDate(props.row.date_dates) }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS" fixed="right" align="left" width="170">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="Approval Status" placement="top-start">
              <el-button size="mini" type="primary" @click="showApprovalStatus(scope.row.approvers)"><i class="el-icon-document-checked"></i></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Delete" placement="top-start">
              <el-button size="mini" type="danger" @click="confirmDelete(scope.row)" v-if="!(scope.row.a_id === '2' || scope.row.is_lock === '1')"><i class="el-icon-delete"></i></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { format } from 'date-fns'
export default {
  name: 'FileList',
  data () {
    return {
      windowWidth: window.innerWidth,
      status: 1,
      items: 5,
      page: 1,
      hasNextPage: false,
      hasPreviousPage: false,
      statuses: [
        {
          value: 0,
          name: 'All'
        },
        {
          value: 1,
          name: 'Waiting'
        },
        {
          value: 2,
          name: 'Approved'
        },
        {
          value: 3,
          name: 'Disapproved'
        }
      ],
      data: [],
      dialogTableVisible: false,
      approvers: []
    }
  },
  watch: {
    status () {
      this.getFiles()
    },
    items () {
      this.page = 1
      this.getFiles()
    }
  },
  computed: {
    isMobile () {
      return this.windowWidth < 992
    }
  },
  mounted () {
    this.getFiles()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    getFiles () {
      this.$http
        .get(`ess.forms-list/${this.$store.getters.user.employeeId}/${this.status}/${this.items}?page=${this.page}`)
        .then(res => {
          this.data = res.data.StatusCode ? res.data.body.data : []
          this.hasNextPage = res.data.StatusCode ? !!res.data.body.next_page_url : false
          this.hasPreviousPage = res.data.StatusCode ? !!res.data.body.prev_page_url : false
        })
    },
    nextPage () {
      this.page++
      this.getFiles()
    },
    prevPage () {
      this.page--
      this.getFiles()
    },
    showApprovalStatus (approvers) {
      this.approvers = approvers
      this.dialogTableVisible = true
    },
    deleteFile (file) {
      this.$http
        .delete(`ess.forms/delete/${file.id}/${file.bft_id}`)
        .then(res => {
          this.$message({
            type: 'success',
            message: 'Deletion success'
          })
          this.getFiles()
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    confirmDelete (file) {
      this.$confirm('Are you sure you want delete this form?', 'Confirm', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.deleteFile(file)
      }).catch((e) => {
        this.$message({
          type: 'info',
          message: 'Deletion cancelled'
        })
      })
    },
    formatDate (date, dateFormat = 'iii, dd MMM yyyy') {
      return format(new Date(date), dateFormat)
    },
    getStatusType (type) {
      if (type === 'Waiting') {
        return 'primary'
      } else if (type === 'Approved') {
        return 'success'
      } else {
        return 'danger'
      }
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.onResize)
    }
  }
}
</script>

<style lang="scss" scoped>
  .expanded-p {
    margin: 0;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
